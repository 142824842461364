import {DashboardMenuOption} from 'components/dashboard-menu/component'
import React from 'react'
import { LayoutsComponent, StripoLayoutsComponent } from './component'

export const routes = [
  {
    action: () => <LayoutsComponent />,
    path: DashboardMenuOption.emailDesign,
  },
  {
    action: () => <StripoLayoutsComponent />,
    path: DashboardMenuOption.stripo,
  },
]
