import { ArticleActivity } from 'components/cs-dashboard/article-activity/component'
import { ContentHistory } from 'components/icons/contentHistory'
import { PanelBarOption } from 'components/panel-bar/types'

import * as React from 'react'
import { ScheduleHistory } from "../../components/cs-dashboard/schedule-history";
import { SourceCheck } from 'components/cs-dashboard/source-check'
import { UrlRequestHistoryComponent } from 'components/analytics/url-request-history'
import { UrlRequestContentBlockComponent } from '../../components/analytics/url-request-content-block'
import { BulkSourceImport } from '../../components/cs-dashboard/bulk-source-import'

const articleActivityIcon = <ContentHistory svgwidth={34.7} svgheight={32.9}/>

export const allSections: PanelBarOption[] = [
  {
    category: '',
    component: ArticleActivity,
    icon: articleActivityIcon,
    name: 'Article Activity',
    route: 'article-activity',
  },
  {
    category: '',
    component: BulkSourceImport,
    icon: articleActivityIcon,
    name: 'Bulk Source Import',
    route: 'bulk-source-import',
  },
  {
    category: '',
    component: ScheduleHistory,
    icon: articleActivityIcon,
    name: 'Schedule History',
    route: 'schedule-history',
  },
  {
    category: '',
    component: SourceCheck,
    icon: articleActivityIcon,
    name: 'Check Source URL',
    route: 'source-check',
  },
  {
    category: '',
    component: UrlRequestHistoryComponent,
    icon: articleActivityIcon,
    name: 'URL Request History',
    route: 'url-request-history',
  },
  {
    category: '',
    component: UrlRequestContentBlockComponent,
    icon: articleActivityIcon,
    name: 'URL Request Content Block',
    route: 'url-request-content-block',
  }
]

export const sections = (
  isSuperUser: boolean,
) => {
  return allSections.filter((item) => {
    if (item.name === 'Bulk Source Import') {
      return isSuperUser
    } else {
      return true
    }
  })
}

export const RETRY_LIMIT = 2
export const RETRY_WAIT_TIME = 5000

export const ACTIVE_COMMUNITY_NOT_FOUND_ERROR = 'Something went wrong. Active community doesn\'t found'
