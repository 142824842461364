import {DashboardMenu, DashboardMenuOption} from 'components/dashboard-menu/component'
import { EmailLayouts } from 'components/email-layout/components'
import { StripoEditor } from 'components/email-layout/stripo'
import React, {Component} from 'react'

export class LayoutsComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.emailDesign} />
        <div>
          <EmailLayouts />
        </div>
      </div>
    );
  }
}

export class StripoLayoutsComponent extends Component {
  public render() {
    return (
      <div>
        <DashboardMenu selected={DashboardMenuOption.stripo} />
        <div>
          <StripoEditor />
        </div>
      </div>
    );
  }
}
